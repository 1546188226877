







































































































import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { TaskAssignment } from "@/types";

@Component({
  metaInfo() {
    return {
      title: "Görev Atamaları / Admin"
    };
  }
})
export default class AdminTasksView extends Vue {
  search = "";
  loading = false;
  headers = [
    {
      text: "Id",
      align: "start",
      filterable: true,
      value: "id"
    },
    { text: "Kullanıcı", value: "user" },
    { text: "Faaliyet", value: "activity" },
    { text: "Başlangıç", value: "startDate"},
    { text: "Bitiş", value: "finishDate"},
    { text: "Durum", value: "status"},
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];
  items = [] as TaskAssignment[];

  async getItems() {
    try {
      this.loading = true;

      var res = await axios.get("/api/task/get-all", {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      console.log(res.data);

      this.items = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Görevler alınamadı.`
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(task: TaskAssignment) {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Görev atamasını silmek istediğinizden emin misiniz?", { title: "Emin misiniz?" });

    if (!confirmation) return;

    try {
      console.log(task);

      // Etkinlik grubunu sil
      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      var res = await axios.delete(`/api/task/${task.id}`, {
        headers: authHeader
      });

      this.$notify({
        type: "success",
        title: "İşlem Tamam",
        text: "Görev başarıyla silindi."
      });

      await this.getItems();
    } catch (e) {
      this.$notify({
        type: "eror",
        title: "Hata!",
        text: "Faaliyet silinemedi."
      });

      throw e;
    }
  }

  async mounted() {
    await this.getItems();
  }
}
