var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ara","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","to":{ name: 'AdminTaskCreate' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_c('span',[_vm._v("YENİ GÖREV")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getItems}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Yükleniyor..."},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstName + " " + item.user.lastName)+" ")]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.activity.title)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.startDate).toLocaleDateString("tr-TR"))+" ")]}},{key:"item.finishDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.finishDate).toLocaleDateString("tr-TR"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'Uncompleted')?_c('v-chip',{attrs:{"color":"orange","text-color":"white","small":""}},[_vm._v("Devam ediyor")]):_vm._e(),(item.status == 'Completed')?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("Tamamlandı")]):_vm._e(),(item.status == 'Rejected')?_c('v-chip',{attrs:{"color":"red","text-color":"white","small":""}},[_vm._v("Red")]):_vm._e(),(item.status == 'Cancelled')?_c('v-chip',{attrs:{"color":"red","text-color":"white","small":""}},[_vm._v("İptal")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
                      name: 'AdminTaskDetails',
                      params: { id: item.id }
                    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-list-item-title',[_vm._v("Gözat")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":{
                      name: 'AdminTaskEdit',
                      params: { id: item.id }
                    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-edit")])],1),_c('v-list-item-title',[_vm._v("Düzenle")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Sil")])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }